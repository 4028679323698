import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { AuthenticationResult, InteractionStatus } from '@azure/msal-browser';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { Analyst } from 'src/app/models/analyst';
import { AnalystService } from 'src/app/services/analyst.service';
import { SessionService } from 'src/app/services/interceptors/session.service';
 
@Component({
  selector: 'app-title-navbar',
  templateUrl: './title-navbar.component.html',
  styleUrls: ['./title-navbar.component.css'],
})
export class TitleNavbarComponent implements OnInit, OnDestroy {
 
  loggedIn = false;
  private readonly _destroying$ = new Subject<void>();
 
  constructor(
    private broadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private sessionService: SessionService,
    private analystService: AnalystService,
    private router: Router
  ) { }
 
  async ngOnInit() {
    await this.msalService.instance.initialize();
 
    this.broadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe(() => {
        this.checkAccount();
      });
    
    this.msalService.instance.handleRedirectPromise().then((response)=>{
      if(response) {
        this.msalService.instance.setActiveAccount(response.account);
      }
    }).catch(console.error);

    this.checkAccount();
  }
 
  private checkAccount() {
    const accounts = this.msalService.instance.getAllAccounts();
    if (accounts && accounts.length > 0) {
      this.loggedIn = true;
      this.msalService.instance.setActiveAccount(accounts[0]);
      this.sessionService.notifyLoginStatuschange(true);

      const hasDetail = localStorage.getItem('url-detail-nav');

      if (hasDetail != null && hasDetail.includes('/booking/detail')) {
        this.router.navigate([hasDetail]); 
      }else
        this.router.navigate(['main']); 
    } else {
      this.loggedIn = false;
    }
  }
 
  login() { 
    // Iniciar sesión con el popup de MSAL
    this.msalService.loginPopup()
      .subscribe({
        next: (response: AuthenticationResult) => {
          if (response != null) {
            this.msalService.instance.setActiveAccount(response.account);
            this.loggedIn = true;
            // sessionStorage.setItem('msal.idtoken', response.idToken);           
            localStorage.setItem('msal.idtoken', response.idToken);           
            this.sessionService.notifyLoginStatuschange(true);

            const hasDetail = localStorage.getItem('url-detail-nav');
            
            if (hasDetail != null && hasDetail.includes('/booking/detail')) {
              this.router.navigate([hasDetail]); 
            }else
              this.router.navigate(['main']); 
          } else {
            this.loggedIn = false;
            this.router.navigate(['emma']);
          }
        },
        error: (err) => {
          console.log("Error during login:", err);
          this.loggedIn = false;
        },
        complete: ()=>{
          this.saveSession(); 
        }
      }); 
  }
 
  private saveSession() {
    this.sessionService.saveSession().subscribe({
      next: (response: any) => {
        console.log('Session saved successfully', response);
        if (response && response.data && (response.data.username && response.data.username != null && response.data.username != '')) {
          this.getAnalystSession(response.data.username);
        }
      },
      error: (err) => {
        console.error('Failed to save session', err);
      }
    });
  }

  getAnalystSession(email: string): void {
    this.analystService.findByEmail(email)
    .subscribe({
      next: (res: any) => {
        this.analystService.analystSession = (res as Analyst);
      },
      error: (err) => {
        console.error(err);
      }
    });
  }
 
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }
}
