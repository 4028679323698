import { NgbdModalConfirmAutofocus } from './shared/dialogs/modal-confirm';
import { CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID } from '@angular/core';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { NgxSpinnerModule } from 'ngx-spinner';
import { ToastrModule } from 'ngx-toastr';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AboutUsComponent } from './components/landing-page/about-us/about-us.component';
import { FooterComponent } from './components/landing-page/footer/footer.component';
import { HomeComponent } from './components/landing-page/home/home.component';
/* Landing-Page */
import { LandingPageComponent } from './components/landing-page/landing-page.component';
import { NavbarComponent } from './components/landing-page/navbar/navbar.component';
import { TitleNavbarComponent } from './components/landing-page/title-navbar/title-navbar.component';
import { DevelopCellFarmComponent } from './components/main/develop-cell-farm/develop-cell-farm.component';
import { DevelopObservabilityComponent } from './components/main/develop-observability/develop-observability.component';
import { DevelopWindowsComponent } from './components/main/develop-windows/develop-windows.component';
import { HelpComponent } from './components/main/help/help.component';
import { MainBackgroundComponent } from './components/main/main-background/main-background.component';
import { MainNavbarComponent } from './components/main/main-navbar/main-navbar.component';
import { MainTitleNavbarComponent } from './components/main/main-title-navbar/main-title-navbar.component';
import { MainComponent } from './components/main/main.component';
import { MaintenanceComponent } from './components/main/maintenance/maintenance.component';
import { AnalystService } from './services/analyst.service';
import { ApplicationService } from './services/application.service';
import { BookingService } from './services/booking.service';
import { ComponentAppService } from './services/component-app.service';
import { InitiativeTypeService } from './services/initiative-type.service';
import { HttpErrorInterceptor } from './services/interceptors/http-error-interceptor.interceptor';
import { TokenInterceptor } from './services/interceptors/token-interceptor.service';
import { TestTypeService } from './services/test-type.service';
import { SharedModule } from './shared/shared.module';
import { AutocompleteLibModule } from 'angular-ng-autocomplete';
import { MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalService } from '@azure/msal-angular';
import { BrowserCacheLocation, IPublicClientApplication, InteractionType, LogLevel, PublicClientApplication } from '@azure/msal-browser';
import localeEs from '@angular/common/locales/es-CO';
import localeEsExtra from '@angular/common/locales/extra/es-CO';
import { ReportComponent } from './components/report/report.component';
import { NgApexchartsModule } from 'ng-apexcharts';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

function loggerCallback(logLevel: LogLevel, message: string) {
  console.log(message)
}

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      clientId: environment.config.clientId,
      redirectUri: environment.config.redirectUri,
      authority: environment.config.authority,
    },
    cache: {
      cacheLocation: BrowserCacheLocation.LocalStorage,
    },
    system: {
      allowNativeBroker: true,
      loggerOptions: {
        loggerCallback,
        logLevel: LogLevel.Info,
        piiLoggingEnabled: false,
      },
    },
  });
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Popup,
    authRequest: {
      scopes: ['user.read']
    }
  }
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map <string, Array<string>>();
  protectedResourceMap.set('https://graph.microsoft.com/v1.0/me', ['user.read']);

  return {
    interactionType: InteractionType.Popup,
    protectedResourceMap
  };
}

registerLocaleData(localeEs, 'es-CO', localeEsExtra);

@NgModule({
  declarations: [
    AppComponent,
    LandingPageComponent,
    HomeComponent,
    NavbarComponent,
    MainComponent,
    LandingPageComponent,
    FooterComponent,
    TitleNavbarComponent,
    AboutUsComponent,
    HelpComponent,
    MainNavbarComponent,
    MainTitleNavbarComponent,
    DevelopWindowsComponent,
    DevelopObservabilityComponent,
    DevelopCellFarmComponent,
    MainBackgroundComponent,
    MaintenanceComponent,
    NgbdModalConfirmAutofocus,
    ReportComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    SharedModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    HttpClientModule,
    NgbModule,
    NgxMaskDirective,
    NgxMaskPipe,
    ToastrModule.forRoot({
      timeOut: 3000,
      positionClass: 'toast-bottom-center',
      preventDuplicates: true,
      closeButton: true,
      progressBar: true,
      progressAnimation: 'decreasing',
    }),
    MsalModule.forRoot(MSALInstanceFactory(),MSALGuardConfigFactory(), MSALInterceptorConfigFactory()),
    AutocompleteLibModule,
    NgApexchartsModule
  ],
  exports: [
    TitleNavbarComponent,
    MainNavbarComponent,
    NavbarComponent,
    FooterComponent,
    NgbdModalConfirmAutofocus,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  providers: [
    InitiativeTypeService,
    AnalystService,
    ComponentAppService,
    BookingService,
    ApplicationService,
    TestTypeService,
    MsalService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
     },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorInterceptor,
      multi: true,
    },
    {
       provide: HTTP_INTERCEPTORS,
       useClass: MsalInterceptor,
       multi: true,
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    { provide: LOCALE_ID, useValue: "es-CO" },
    provideNgxMask()
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
