import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Booking } from 'src/app/models/booking';
import { Comment } from 'src/app/models/comment';
import { AnalystService } from 'src/app/services/analyst.service';
import { BookingService } from 'src/app/services/booking.service';
import { CommentService } from 'src/app/services/comment.service';
import { Tooltip } from "bootstrap";
import { Collapse } from "bootstrap";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NgbdModalConfirmAutofocus } from 'src/app/shared/dialogs/modal-confirm';
import { MenuRoutes } from 'src/app/models/menu-routes';
import { MenuService } from 'src/app/services/menu/menu.service';
import { timer } from 'rxjs';

@Component({
  selector: 'app-booking-detail',
  templateUrl: './booking-detail.component.html',
  styleUrls: ['./booking-detail.component.css']
})
export class BookingDetailComponent implements OnInit {

  data_booking: Partial<Booking> = {};

  commentDTO: Comment = {};
  menuRoutes: MenuRoutes[];
  showLoading: boolean = true;

  constructor(private bookingService: BookingService,
              private activatedRoute: ActivatedRoute,
              private commentService: CommentService,
              private analystService: AnalystService,
              private toastr: ToastrService,
              private router: Router,
              private modalService: NgbModal,
              private menuService: MenuService) {}

  ngOnInit(): void {
    const bookingId = this.activatedRoute.snapshot.params['bookingId'];
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    this.data_booking.bookingId = bookingId;

    if (tooltipTriggerList.length > 0) {
      new Tooltip(tooltipTriggerList[0]);
    }
    
    this.getSystemRoles();
    timer(4000);
    this.getBookingDetail(bookingId);
    this.getComments(bookingId);
  }

  toggleCollapse(collapse: string):void {
    const collapseElementList: any = document.querySelectorAll('.'+collapse);
    [...collapseElementList].map(collapseEl => new Collapse(collapseEl).toggle());
  }

  getBookingDetail(bookingId: number): void {
    this.bookingService
      .findBookingById(bookingId)
      .subscribe({
        next: (res: any) => {
          localStorage.removeItem('url-detail-nav');
          this.data_booking = res;
        },
        error: (error: any) => {
          console.error(error);
          if (error.status == 406) {
            timer(1000);
            this.getBookingDetail(bookingId);
          }else {
            this.toastr.error(`Ha ocurrido un error, por favor contacta al administrador de la plataforma!`,'Sistema');
          }
        }
      });
  }

  getComments(bookingId: number): void {
    this.commentService
      .getByBookingId(bookingId)
      .subscribe({
        next: (res: any) => {
          this.showLoading = false;
          if (res) {
            this.data_booking.detailsComments = res;
          }
        },
        error: (error: any) => {
          console.error(error);
          if (error.status == 406) {
            timer(1000);
            this.getComments(bookingId);
          }else {
            this.toastr.error(`Ha ocurrido un error, por favor contacta al administrador de la plataforma!`,'Sistema');
          }
        }
      });
  }

  save() {
    this.commentDTO.bookingId = this.data_booking.bookingId;
    this.commentDTO.analyst = {analystId: this.analystService.analystSession.analystId};
    
    if (this.commentDTO.comment != null && this.commentDTO.comment != '' && this.commentDTO.analyst != null && this.commentDTO.analyst != undefined) {
      this.commentService
      .create(this.commentDTO)
      .subscribe({
        next: () => {
          this.toastr.success(`Comentario Agregado con exito!`,'Aplicación');
          this.getComments(this.data_booking.bookingId);
          this.commentDTO.comment = '';
        },
        error: (error) => {
          console.error(error);
          this.toastr.error(`Ha ocurrido un error, por favor contacta al administrador de la plataforma!`,'Sistema');
        }
      })
    }else {
      this.toastr.warning(`El campo "comentario" es obligatorio`,'Validación');
    }
  }

  back(): void {
    this.router.navigate(['main/booking']);
  }

  copyLink():void {
    const url = window.location.href;
    navigator.clipboard.writeText(url).then(() => {
      this.toastr.info('Enlace copiado!', 'Agendamiento' , {
        timeOut: 1000,
        progressBar: false,
        progressAnimation: 'decreasing',
        positionClass: 'toast-top-right'
      });
    });
  }

  getSystemRoles() {
    this.menuService.findMenu()
      .subscribe((response: MenuRoutes[]) => {
        this.menuRoutes = response;
      });
  }

  openDialog(isApprove: boolean): void {
    NgbdModalConfirmAutofocus.isWindow = (!isApprove)?1:2;
    const modalRef = this.modalService.open(NgbdModalConfirmAutofocus, {});
    modalRef.result.then(
      (data) => {
        if (isApprove)
          this.approveBooking();
        else
          this.cancelBooking();
      },
      (error) => {
        console.error(error);
        return;
      }
    );
  }

  verifyState(stateId: number, isApprove: boolean): boolean {
    const rejected = 1005;
    const approved = 1004;
    const finish = 1003;
    const execute = 1002;

    if (stateId === rejected || stateId === finish || stateId === execute || (isApprove && stateId === approved)) {
      return false;
    } else {
      return true;
    }
  }

  approveBooking(): void {
    const bookingId = this.data_booking.bookingId;
    this.bookingService
      .approveBooking(bookingId)
      .subscribe({
        next: (res: any) => {
          this.data_booking = res.data;
          this.toastr.success(`${res.message}`, 'Aprobar Agenda [' + bookingId + ']');
        },
        error: (error: any) => {
          console.error(error);
          this.toastr.error(`${error.error.errors[0].detail}`, 'Aprobar Agenda [' + bookingId + ']');
        }
      });
  }

  cancelBooking(): void {
    const bookingId = this.data_booking.bookingId;
    this.bookingService
      .cancelBooking(bookingId)
      .subscribe({
        next: (res: any) => {
          this.data_booking = res.data;
          this.toastr.success(`${res.message}`, 'Cancelar Agenda [' + bookingId + ']');
        },
        error: (error: any) => {
          console.error(error);
          this.toastr.error(`${error.error.errors[0].detail}`, 'Cancelar Agenda [' + bookingId + ']');
        }
      });
  }

  validateRol(rolName: string): boolean {
    if (this.menuRoutes != null) {
      const hasRole = this.menuRoutes.find((menuItem: MenuRoutes) => menuItem.roleName === rolName);
      return (hasRole)?true:false;
    }
    return false;
  }
}
