import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { GENERIC_LABELS } from '../const/generic.const';

@Component({
  selector: 'ngbd-modal-confirm-autofocus',
  template: `
    <div class="modal-header">
      <h4 class="modal-title" id="modal-title">
        {{ (validateWindow == 0)?genericLabels.cancelWindowModalTitle:(validateWindow == 1)?genericLabels.cancelAgendaModalTitle:genericLabels.approveAgendaModalTitle }}
      </h4>
      <button
        type="button"
        class="btn-close"
        aria-label="Close button"
        aria-describedby="modal-title"
        (click)="modal.dismiss('')"
      >
        x
      </button>
    </div>
    <div class="modal-body">
      <p>
        <strong> {{ (validateWindow == 0 || validateWindow == 1)?genericLabels.cancelWindowModalBody:genericLabels.approveAgendaModalBody }}</strong>
      </p>
    </div>
    <div class="modal-footer">
      <button
        type="button"
        class="btn btn-outline-secondary"
        (click)="modal.dismiss('')"
      >
        {{ genericLabels.buttonCancel }}
      </button>
      <button
        type="button"
        ngbAutofocus
        class="btn btn-danger"
        (click)="modal.close('')"
      >
        {{ genericLabels.buttonConfirm }}
      </button>
    </div>
  `,
})
export class NgbdModalConfirmAutofocus implements OnInit {
  genericLabels = GENERIC_LABELS;
  static isWindow: number = 0; // 0 - ventana, 1- cancelar agenda, 2- aprobar agenda
  validateWindow: number = NgbdModalConfirmAutofocus.isWindow;
  constructor(public modal: NgbActiveModal) {}

  ngOnInit(): void {}
}
