import { map, pluck } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Booking, BookingRequest } from '../models/booking';
import { ReportFilter } from '../models/report-filter';
import { ReportResponse } from '../models/report-response';

@Injectable({
  providedIn: 'root',
})
export class BookingService {
  readonly API = environment.config.endpoints.API;

  constructor(private http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  createBooking(booking: Booking): Observable<any> {
    const ENDPOINT = `${this.API}/booking/create`;
    return this.http
      .post<any>(ENDPOINT, booking)
      .pipe(map((response) => response));
  }

  updateBooking(booking: Booking): Observable<Booking> {
    const ENDPOINT = `${this.API}/booking/update/${booking.bookingId || ''}`;
    return this.http.post<Booking>(ENDPOINT, booking);
  }

  findBookingByFilter(
    bokingRequest: BookingRequest
  ): Observable<Array<Booking>> {
    const ENDPOINT = `${this.API}/booking/findBookingByFilter`;
    return this.http
      .post<Array<Booking>>(ENDPOINT, bokingRequest, this.httpOptions)
      .pipe(
        pluck('data'),
        map((response: Array<Booking>) => response)
      );
  }

  findByUserId(userId: string): Observable<Array<Booking>> {
    const ENDPOINT = `${this.API}/booking/findByUserId/${userId || ''}`;
    return this.http.get<Array<Booking>>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response: Array<Booking>) => response)
    );
  }

  findBookingById(bokingId: any): Observable<Booking> {
    const ENDPOINT = `${this.API}/booking/findById/${bokingId || ''}`;
    return this.http.get<any>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response: Booking) => response)
    );
  }

  approveBooking(bookingId: number): Observable<Booking> {
    const ENDPOINT = `${this.API}/booking/approveBooking/${bookingId || ''}`;
    console.log('ENDPOINT ', ENDPOINT);
    
    return this.http.patch<Booking>(ENDPOINT, {});
  }

  cancelBooking(bookingId: number): Observable<Booking> {
    const ENDPOINT = `${this.API}/booking/cancelBooking/${bookingId || ''}`;
    console.log('ENDPOINT ', ENDPOINT);
    
    return this.http.patch<Booking>(ENDPOINT, {});
  }

  getReport(reportFilter: ReportFilter): Observable<any> {
    const ENDPOINT = `${this.API}/booking/report`;
    return this.http
      .post<ReportFilter>(ENDPOINT, reportFilter, this.httpOptions)
      .pipe(
        pluck('data'),
        map((response: Array<ReportResponse>) => response)
      );
  }

  getExcel(reportFilter: ReportFilter) {
    const ENDPOINT = `${this.API}/booking/report/export`;
    return this.http.post(ENDPOINT, reportFilter, { responseType: 'blob' });
  }
}
