import { pluck, map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import {
  RequestedWindow,
  RequestWindow,
  WindowFilter,
  WindowResponse,
} from 'src/app/models/window';
import { ReportFilter } from 'src/app/models/report-filter';
import { ReportResponse } from 'src/app/models/report-response';

@Injectable({
  providedIn: 'root',
})
export class WindowService {
  readonly API = environment.config.endpoints.API;
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  constructor(private http: HttpClient) {}

  findWindowsByDate(dateTime: Date): Observable<any> {
    const ENDPOINT = `${
      this.API
    }/window/findWindowsByDate/${dateTime.toISOString()}`;
    return this.http.get<RequestedWindow>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response)
    );
  }

  createWindow(requestWindow: RequestWindow): Observable<any> {
    const ENDPOINT = `${this.API}/window/create`;
    return this.http
      .post<any>(ENDPOINT, requestWindow)
      .pipe(map((response) => response));
  }

  findById(windowId: number): Observable<any> {
    const ENDPOINT = `${this.API}/window/findById/${windowId}`;
    return this.http.get<any>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response) => response)
    );
  }

  findWindowsByFilter(
    windowFilter: WindowFilter
  ): Observable<Array<WindowResponse>> {
    const ENDPOINT = `${this.API}/window/listWindowFilter`;
    return this.http
      .post<any>(ENDPOINT, windowFilter)
      .pipe(map((response) => response.data));
  }

  updateWindow(requestWindow: RequestWindow, state: number): Observable<any> {
    const ENDPOINT = `${this.API}/window/update/${state}`;
    return this.http.post<any>(ENDPOINT, requestWindow).pipe(
      pluck('data'),
      map((response) => response)
    );
  }

  getReport(reportFilter: ReportFilter): Observable<any> {
    const ENDPOINT = `${this.API}/window/report`;
    return this.http
      .post<ReportFilter>(ENDPOINT, reportFilter, this.httpOptions)
      .pipe(
        pluck('data'),
        map((response: Array<ReportResponse>) => response)
      );
  }

  getExcel(reportFilter: ReportFilter) {
    const ENDPOINT = `${this.API}/window/report/export`;
    return this.http.post(ENDPOINT, reportFilter, { responseType: 'blob' }); 
  }
}
