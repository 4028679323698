import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbDropdownConfig } from '@ng-bootstrap/ng-bootstrap';
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions,
  ApexResponsive,
  ApexYAxis,
  ApexFill
} from "ng-apexcharts";
import { Applicative } from 'src/app/models/applicative';
import { ApplicationService } from 'src/app/services/application.service';
import { BookingService } from 'src/app/services/booking.service';
import { AutocompleteComponent } from 'angular-ng-autocomplete';
import { ReportFilter } from 'src/app/models/report-filter';
import { WindowService } from 'src/app/services/windows/window.service';
import { ReportResponse } from 'src/app/models/report-response';

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  fill: ApexFill;
  responsive: ApexResponsive[];
  labels: any;
  title: any;
};

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.css']
})
export class ReportComponent implements OnInit {

  @ViewChild("chart") chart: ChartComponent;
  @ViewChild('auto') auto: AutocompleteComponent;
  public chartOptions: Partial<ChartOptions>;
  public chartOptionsBar: Partial<ChartOptions>;
  showChart: boolean = false;

  applications: Applicative[];
  keywordApp = 'applicativeName';

  years: number[] = [];
  months: { name: string; val: number, checked: boolean }[] = [];
  listStatus: { name: string; val: number, checked: boolean }[] = [];
  monthNames = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio',
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];

  filterForm = new FormGroup({
    year: new FormControl(new Date().getFullYear(), []),
    month: new FormControl([], []),
    status: new FormControl([], []),
    user: new FormControl('', []),
    applicativeId: new FormControl(null, []),
    type: new FormControl('booking', [])

  });

  constructor(config: NgbDropdownConfig, private apiService: BookingService, private applicationService: ApplicationService, private apiWinService: WindowService) {
    config.autoClose = 'outside';
  }

  ngOnInit(): void {
    this.years = this.getYears(2);
    this.getStatusList();
    this.getApplicatives();
    this.updateMonths();
  }

  getYears(x: number): number[] {
    const currentYear = new Date().getFullYear();
    const startYear = 2024;
  
    return Array.from({ length: x }, (_, i) => {
      const year = currentYear - i;
      return year >= startYear ? year : null; // Solo incluir si es >= 2024
    }).filter(year => year !== null) as number[];
  }

  getMonths(selectedYear: number): { name: string; val: number, checked: boolean }[] {
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth() + 1;
  
    return this.monthNames
      .map((name, index) => ({ name, val: index + 1, checked: false }))
      .filter(month => selectedYear < currentYear || month.val <= currentMonth);
  }

  updateMonths(): void {
    const selectedYear = this.filterForm.get('year').value;
    this.months = this.getMonths(selectedYear);
  }

  getStatusList(): void {
    this.listStatus = [
    	{name: 'Programada', val: 1001, checked: false},
    	{name: 'Finalizada', val: 1003, checked: false},
    	{name: 'Aprobada', val: 1004, checked: false},
    	{name: 'Cancelada', val: 1005, checked: false},
    	{name: 'Rechazada', val: 1006, checked: false},
    	{name: 'Pendiente', val: 1007, checked: false},
    	{name: 'Activa', val: 1008, checked: false},
    	{name: 'Pendiente por Admin.', val: 1009, checked: false},
    	{name: 'En ejecucion', val: 1002, checked: false},
    	{name: 'En negociacion', val: 1010, checked: false}]
  }

  getApplicatives(): void {
    this.applicationService.getApplications().
    subscribe({
      next: (res: any) => {
        this.applications = res.data;
      },
      error: (error: any) => {
        console.error(error);
      }
    });
  }

  addToFilter(key: string, dto: {name: string; val: number, checked: boolean }): void {
    dto.checked = !dto.checked;
    let current = this.filterForm.get(key).value;
    if (current.length == 0) {
      current.push(dto.val);
    }else {
      const isAdded = current.find((item: number) => item == dto.val);
      if (isAdded == undefined) {
        current.push(dto.val);
      }else {
        current = current.filter((item: number) => item != dto.val);
      }
    }
    this.filterForm.get(key).setValue(current);
  }

  selectAppEvent(itemApp, isClear: boolean) {
    this.filterForm.get('applicativeId').setValue(((itemApp == undefined && isClear)?null:itemApp.applicativeId));
  }

  clear(): void {
    this.filterForm.reset({
      year: new Date().getFullYear(),
      user: '',
      type: 'booking'
    });
    this.listStatus.forEach((item) => { item.checked = false});
    this.months.forEach((item) => item.checked = false);
    this.auto.clear();
    this.auto.close();
    this.showChart = false;
  }

  filter(): void {
    const { type, ...data} = this.filterForm.value;
    const apiTemp = (type == 'booking')?this.apiService:this.apiWinService;
    
    apiTemp.getReport((data as ReportFilter))
      .subscribe({
        next: (res: ReportResponse[]) => {
          if (res && res.length > 0) {
            const labels = res.map((item) => `${this.monthNames[(item.month - 1)]} - ${item.state}`);
            const series = res.map((item) => item.quantity);
            this.showChart = true;
            this.renderChart(series, labels);
            this.renderChartBar(series, labels);
          }else {
            this.showChart = false;
          }
        },
        error: (error: any) => {
          console.error(error);
        }
    })
  }

  renderChart(series: any[], labels: string[]):void {
    this.chartOptions = {
      series,
      chart: {
        width: 550,
        type: 'pie',
      },
      labels,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: { width: 320 },
            legend: { position: 'bottom' },
          },
        },
      ],
      title: {
        text: 'Agendamientos',
      },
    };
  }

  renderChartBar(series: any[], labels: string[]):void {
    this.chartOptionsBar = {
      series: [
        {
          name: "",
          data: series
        }
      ],
      chart: {
        width: 600,
        height: 300,
        type: "bar"
      },
      plotOptions: {
        bar: {
          dataLabels: {
            position: "top" // top, center, bottom
          }
        }
      },
      dataLabels: {
        enabled: true,
        formatter: function(val) {
          return val + "%";
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: ["#304758"]
        }
      },
      xaxis: {
        categories: labels,
        position: "top",
        labels: {
          offsetY: -18
        },
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5
            }
          }
        },
        tooltip: {
          enabled: true,
          offsetY: -35
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [50, 0, 100, 100]
        }
      },
      yaxis: {
        axisBorder: {
          show: false
        },
        axisTicks: {
          show: false
        },
        labels: {
          show: false,
          formatter: function(val) {
            return val + "%";
          }
        }
      },
      title: {
        text: "Estado Agendamientos",
        floating: 0,
        offsetY: 320,
        align: "center",
        style: {
          color: "#444"
        }
      }
    };
  }

  generateExcel(): void {
    const { type, ...data} = this.filterForm.value;
    const apiTemp = (type == 'booking')?this.apiService:this.apiWinService;
    
    apiTemp.getExcel((data as ReportFilter))
      .subscribe({
        next: (res: Blob) => this.saveFile(res, (type == 'booking'?`Reporte_Agendamientos`:`Reporte_Ventanas`)+'.xlsx'),
        error: (err) => console.error('Error al descargar el archivo', err),
    })
  }

  saveFile(blob: Blob, filename: string): void {
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = filename;
    a.click();
    window.URL.revokeObjectURL(url);
  }
}
