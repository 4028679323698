import { AfterViewInit, Component, OnInit } from '@angular/core';
import { MainMenuService } from 'src/app/services/center-of-services/menu.service';
import { MainMenu } from 'src/app/models/center-of-services/main-menu';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';

@Component({
  selector: 'app-center-of-services',
  templateUrl: './center-of-services.component.html',
  styleUrls: ['./center-of-services.component.css'],
})
export class CenterOfServicesComponent implements OnInit, AfterViewInit {

  private URL_AZURE_WIKI: string = 'https://dev.azure.com/banistmo/VP%20Servicios%20Corporativos/_wiki/wikis/VP-Servicios-Corporativos.wiki';

  menu: Array<MainMenu> = [];
  userName: string = '';

  optionsAzure = [
    {name: 'Ventanas', url: this.URL_AZURE_WIKI + '/39407/Como-Crear-las-Ventanas-en-la-App-Agenda', icon: 'calendar.svg'},
    {name: 'Agendamiento', url: this.URL_AZURE_WIKI + '/39408/Como-Crear-sus-Agendamientos-en-Nueva-App-Agenda', icon: 'book.svg'},
    {name: 'Kobiton', url: this.URL_AZURE_WIKI + '/39398/Modelo-de-Gobierno-de-Kobiton', icon: 'diamond.svg'},
    {name: 'Solicitud set de data (Ambiente no productivo)', url: this.URL_AZURE_WIKI + '/39409/Solicitud-SET-de-Datos-Ambientes-No-Productivos', icon: 'cards.svg'},
    {name: 'Solicitud de data productiva (Ambiente no productivo)', url: this.URL_AZURE_WIKI + '/39410/Solicitud-de-Data-Productiva-para-Ambientes-No-Productivos', icon: 'statistics.svg'},
    {name: 'Entrega de ambiente no productivo', url: this.URL_AZURE_WIKI + '/39382/Entrega-de-Ambientes-No-productos', icon: 'light.svg'},
    {name: 'Solicitud de Mantenimientos - Reserva', url: this.URL_AZURE_WIKI + '/39397/Mantenimientos-Reserva-en-Ambientes-No-productivos', icon: 'gear-incidentes.svg'},
    {name: 'Atención de los incidentes', url: this.URL_AZURE_WIKI + '/39401/Modelo-de-atenci%C3%B3n-de-los-incidentes-y-casos-problemas', icon: 'nodes.svg'},
    {name: 'Reporte de incidentes kobiton', url: 'https://bancolombia.sharepoint.com/:f:/r/teams/EMMA-BANISTMO/Documentos%20compartidos/General?csf=1&web=1&e=eUWKWJ', icon: 'magnifying-glass.svg'}
  ]

  constructor(
    private authService: MsalService
  ) {}

  ngAfterViewInit(): void {
    const loginAccount = this.authService.instance.getActiveAccount();
    this.userName = (loginAccount && loginAccount.idTokenClaims) ? ', '+loginAccount.idTokenClaims.name : '';
  }

  ngOnInit(): void {
    //this.getMainMenu();
  }

  // getMainMenu() {
  //   this.mainMenuService
  //     .getMainMenu()
  //     .subscribe((response: Array<MainMenu>) => {
  //       this.menu = response;
  //     });
  // }

  // goTo(url: string) {
  //   this.router.navigate([url]);
  // }

  goToWiki(url: string):void {
    window.open(url, '_blank');
  }

  getGreet(): string {
    const ahora = new Date();
    const hora = ahora.getHours();

    if (hora < 12) {
        return "¡Buenos días!";
    } else if (hora < 18) {
        return "¡Buenas tardes!";
    } else {
        return "¡Buenas noches!";
    }
  }

}
