<div class="bc-height container mb-4">
    <div class="card shadow-lg">
      <div class="card-body">
        <div class="row">
            <div class="col-12 text-center" *ngIf="showLoading">
                <h3 class="text-start mb-5">Agendamiento No. <b>{{data_booking.bookingId}}</b></h3>
                <div class="spinner-border text-warning" role="status">
                  <span class="visually-hidden">Loading...</span>
                </div>
            </div>
            <div class="col-12" *ngIf="!showLoading">
                <div class="d-flex justify-content-between">
                    <h3>Agendamiento No. <b>{{data_booking.bookingId}}</b> [{{data_booking.state?.description}}]</h3>
                    <button type="button" class="btn btn-primary rounded" (click)="copyLink()" data-bs-toggle="tooltip" data-bs-placement="left" data-bs-title="Copiar"><i class="fas fa-link"></i> Enlace</button>
                </div>
                <section class="ms-4 my-4">
                    <div class="d-flex">
                        <div>
                            <h5 class="mb-0">Fecha creación</h5>
                            <p class="mb-0">{{data_booking.createdDate | date: "d 'de' MMMM 'del' y, h:mm a"}}</p>
                        </div>
                        <div class="mx-5">
                            <h5 class="mb-0">Fecha inicio</h5>
                            <p class="mb-0">{{data_booking.startDate | date: "d 'de' MMMM 'del' y, h:mm a"}}</p>
                        </div>
                        <div>
                            <h5 class="mb-0">Fecha fin</h5>
                            <p class="mb-0">{{data_booking.endDate | date: "d 'de' MMMM 'del' y, h:mm a"}}</p>
                        </div>
                    </div>
                </section>
                <hr>


                <div class="accordion accordion-flush" id="accordion-detail">
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="headingInfoAgenda">
                        <button class="accordion-button" type="button" (click)="toggleCollapse('collapse-detail')" aria-expanded="true" aria-controls="collapse-info-agenda">
                          Información Agendamiento
                        </button>
                      </h2>
                      <div id="collapse-info-agenda" class="accordion-collapse collapse collapse-detail show" aria-labelledby="headingInfoAgenda">
                        <div class="accordion-body">
                            <section>
                                <h4><i class="fas fa-user-tie"></i> Analista</h4>
                                <ng-container *ngTemplateOutlet="analiyst;context:{data: data_booking}"></ng-container>
                                <hr>
                            </section>
                            <section>
                                <h4><i class="fas fa-bullhorn"></i> Iniciativa</h4>
                                <div class="d-flex ms-3 pb-3 sub-item">
                                    <div class="ms-3 me-5">
                                        <h5 class="mb-0 ms-1">Código</h5>
                                        <p class="mb-0 ms-2">{{data_booking.initiative?.initiativeCode}}</p>
                                    </div>
                                    <div class="ms-5">
                                        <h5 class="mb-0">Descripción</h5>
                                        <p class="mb-0">{{data_booking.initiative?.description}}</p>
                                    </div>
                                </div>
                                <h4 class="mt-3"><i class="fas fa-vote-yea"></i> Tipo Iniciativa</h4>
                                <div class="d-flex ms-3 pb-3 sub-item">
                                    <div class="ms-4 me-5">
                                        <h5 class="mb-0">Código</h5>
                                        <p class="mb-0">{{data_booking.initiative?.initiativeType.initiativeTypeCode}}</p>
                                    </div>
                                    <div class="ms-5">
                                        <h5 class="mb-0">Descripción</h5>
                                        <p class="mb-0">{{data_booking.initiative?.initiativeType.description}}</p>
                                    </div>
                                </div>
                                <h4 class="mt-3"><i class="fas fa-user-tie"></i> Analista - Líder</h4>
                                <ng-container *ngTemplateOutlet="analiyst;context:{data: data_booking.initiative}"></ng-container>
                                <hr>
                            </section>
                            <section>
                                <h4><i class="fas fa-list"></i> Componentes</h4>
                                <ng-container *ngFor="let d_component of data_booking.detailsComponents">
                                    <div class="d-flex ms-2 pb-3">
                                        <div class="ms-4">
                                            <h5 class="mb-0">Descripción</h5>
                                            <p class="mb-0">{{d_component.description}}</p>
                                        </div>
                                    </div>
                                </ng-container>
                                <hr>
                            </section>
                            <section>
                                <h4><i class="fas fa-desktop"></i> Aplicativos</h4>
                                <ng-container *ngFor="let d_applicative of data_booking.detailsApplicatives">
                                    <div class="d-flex ms-2 pb-3">
                                        <div class="ms-2">
                                            <h5 class="mb-0">Código</h5>
                                            <p class="mb-0 me-2">{{d_applicative.applicativeCode}}</p>
                                        </div>
                                        <div class="ms-5">
                                            <h5 class="mb-0">Descripción</h5>
                                            <p class="mb-0 me-2">{{d_applicative.description}}</p>
                                        </div>
                                    </div>
                                </ng-container>
                            </section>
                        </div>
                      </div>
                    </div>
                    <div class="accordion-item">
                      <h2 class="accordion-header" id="booking-comments">
                        <button class="accordion-button collapsed" type="button" (click)="toggleCollapse('collapse-comment')" aria-expanded="false" aria-controls="collapse-comment">
                          Comentarios
                        </button>
                      </h2>
                      <div id="collapse-comment" class="accordion-collapse collapse collapse-comment" aria-labelledby="booking-comments">
                        <div class="accordion-body">
                          <div class="content-comments">
                            <ng-container *ngFor="let comment of data_booking.detailsComments; let last = last">
                                <section class="d-flex" [ngClass]="{'justify-content-end': data_booking.analyst?.analystId == comment.createdBy, 'mb-3': !last}">
                                    <div class="p-3 comment">
                                        <p class="mb-1">{{ comment.comment }}</p>
                                        <p class="mb-0" [ngClass]="data_booking.analyst?.analystId == comment.createdBy?'me-2 text-end':'ms-2'"><i class="fas fa-user-astronaut"></i> {{ comment.createdBy }}</p>
                                        <p class="mb-0" [ngClass]="data_booking.analyst?.analystId == comment.createdBy?'me-3 text-end':'ms-3'"><i class="fas fa-calendar-alt"></i> {{ comment.createdDate | date: "d 'de' MMMM, y; h:mm a" }}</p>
                                    </div>
                                </section>
                            </ng-container>
                        </div>
                        <div class="mt-3">
                            <textarea class="form-control" id="add-comment" [(ngModel)]="commentDTO.comment" name="addComment" rows="3" placeholder="Agrega un comentario"></textarea>
                            <button class="btn btn-primary m-2" (click)="save()">Agregar</button>
                        </div>
                        </div>
                      </div>
                    </div>
                </div>

            </div>
            <div class="col-12">
                <div class="buttons mt-2 d-flex justify-content-end">
                    <button class="btn btn-dark m-2" (click)="back()">
                      Regresar
                    </button>
                    <button class="btn btn-danger m-2" *ngIf="verifyState(data_booking.state?.stateId, false) && validateRol('ROLE_ADMIN')" (click)="openDialog(false)">
                        Cancelar
                    </button>
                    <button class="btn btn-secondary m-2" (click)="openDialog(true)" *ngIf="verifyState(data_booking.state?.stateId, true) && validateRol('ROLE_ADMIN')">
                        Aprobar
                    </button>
                </div>
            </div>
        </div>
      </div>
    </div>
</div>

<ng-template #analiyst let-data="data">
    <div class="d-flex">
        <div class="d-flex">
            <div class="ms-4 me-5">
                <h5 class="mb-0 ms-1">Nombre</h5>
                <p class="mb-0 ms-1">{{data?.analyst?.name}}</p>
            </div>
            <div>
                <h5 class="mb-0">Correo</h5>
                <p class="mb-0">{{data?.analyst?.email}}</p>
            </div>
        </div>
    </div>
</ng-template>