import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Comment } from '../models/comment';
import { map, Observable, pluck } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommentService {

  readonly API = environment.config.endpoints.API;

  constructor(private http: HttpClient) {}

  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
  };

  create(comment: Comment): Observable<any> {
    const ENDPOINT = `${this.API}/comment/create`;
    return this.http
      .post<any>(ENDPOINT, comment)
      .pipe(map((response) => response));
  }

  getByBookingId(bokingId: number): Observable<any> {
    const ENDPOINT = `${this.API}/comment/findByBookingId/${bokingId || ''}`;
    return this.http.get<any>(ENDPOINT, this.httpOptions).pipe(
      pluck('data'),
      map((response: Comment) => response)
    );
  }
}
