<div class="bc-height container bc-center">
  <div class="card bc-outbox">
    <div class="card-body">
      <h1 class="card-title w-100 text-center mb-0">Administración de Entornos de Prueba</h1>
      <h1 class="w-100 text-center mb-0 separator-point">• •</h1>
      <h3 class="card-title w-100 text-center">{{getGreet() + userName}}</h3>
      <h3 class="w-100 text-center mb-5">- Procedimientos -</h3>
      <div class="clearfix"></div>
      <div class="row">
        <div class="col-md-12">
          <div class="row row-cols-md-3">
            <div class="col mb-4" *ngFor="let option of optionsAzure">
              <div class="card mini-card" (click)="goToWiki(option.url)">
                <div class="bc-center">
                  <img src="assets/img/icons/center-of-services-icons/{{ option.icon }}" class="img-fluid" alt="" />
                </div>
                <div class="card-body bc-center">
                  <h4 class="card-title text-center m-0">{{ option.name }}</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
