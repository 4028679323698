<div class="container-fluid bc-height container-report">
    <div class="row justify-content-md-center">
        <div class="col-md-11 card p-4 m-2 shadow-lg">
            <h3 class="w-100 p-2 m-0 text-center">Generación de reportes</h3>
            <form [formGroup]="filterForm">
                <section class="filter d-flex">
                    <div class="form-group col-md-1 p-2">
                        <label for="report-type" class="font-weight-bold">Tipo</label>
                        <select id="report-type" class="form-control custom-select" formControlName="type">
                            <option value="booking">Agendamientos</option>
                            <option value="window">Ventanas</option>
                        </select>
                    </div>
                    <div class="form-group col-md-1 p-2">
                        <label for="report-anio" class="font-weight-bold">Año</label>
                        <select id="report-anio" class="form-control custom-select" (change)="updateMonths()" formControlName="year">
                            <option *ngFor="let year of years" [value]="year">{{ year }}</option>
                        </select>
                    </div>
                    <div class="form-group col-md-1 p-2">
                        <label for="report-month" class="font-weight-bold">Mes</label>
                        <div ngbDropdown> 
                            <button class="btn btn-warning" id="report-month" ngbDropdownToggle>- Mes -</button> 
                            <div ngbDropdownMenu="report-month"> 
                                <ul class="list-unstyled m-0">
                                    <li *ngFor="let month of months">
                                        <div ngbDropdownItem class="form-check">
                                            <input class="form-check-input" type="checkbox" (click)="addToFilter('month', month)" [checked]="month.checked" [id]="'month-' + month.name">
                                            <label class="form-check-label ms-2" [for]="'month-' + month.name">
                                                {{ month.name }}
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </div> 
                        </div> 
                    </div>
                    <div class="form-group col-md-2 p-2">
                        <label for="report-status" class="font-weight-bold">Estado</label>
                        <div ngbDropdown> 
                            <button class="btn btn-warning" id="report-status" ngbDropdownToggle>- Estado -</button> 
                            <div ngbDropdownMenu="report-status"> 
                                <ul class="list-unstyled m-0">
                                    <li *ngFor="let status of listStatus">
                                        <div ngbDropdownItem class="form-check">
                                            <input class="form-check-input" type="checkbox" (click)="addToFilter('status', status)" [checked]="status.checked" [id]="'status-' + status.name">
                                            <label class="form-check-label ms-2" [for]="'status-' + status.name">
                                                {{ status.name }}
                                            </label>
                                        </div>
                                    </li>
                                </ul>
                            </div> 
                        </div> 
                    </div> 
                    <div class="form-group col-md-2 p-2">
                        <label for="report-user" class="font-weight-bold">Usuario</label>
                        <input id="report-user" class="form-control" type="text" placeholder="Ingrese un usuario" formControlName="user"/>
                    </div>
                    <div class="form-group col-md-2 p-2">
                        <label for="report-qpplicative">Aplicativo</label>
                        <ng-autocomplete
                          id="report-application"
                          name="report-application"
                          #auto
                          [data]="applications"
                          [searchKeyword]="'applicativeName'"
                          [itemTemplate]="itemTemplate"
                          [notFoundTemplate]="notFoundTemplate"
                          placeholder="- Aplicativos -"
                          notFoundText="No se encontró aplicativo"
                          class="ng-autocomplete"
                          (selected)="selectAppEvent($event, false)"
                          (inputCleared)="selectAppEvent($event, true)">
                        </ng-autocomplete>
      
                        <ng-template #itemTemplate let-item>
                          <a [innerHTML]="item.applicativeName"></a>
                        </ng-template>
      
                        <ng-template #notFoundTemplate let-notFound>
                          <div [innerHTML]="notFound"></div>
                        </ng-template>
                      </div>
                    <div class="form-group col-md p-2 d-flex align-items-end">
                        <button class="btn btn-dark rounded" (click)="clear()"><i class="fas fa-trash"></i> Limpiar</button>
                        <button class="btn btn-primary rounded mx-2" (click)="filter()"><i class="fas fa-filter"></i> Filtrar</button>
                        <button class="btn btn-success rounded text-white" (click)="generateExcel()"><i class="fas fa-file-download"></i> Excel</button>
                    </div>
                </section>
            </form>
            <hr>
            <h1 *ngIf="!showChart" class="text-center mt-4">- No se encontraron registros para los filtros seleccionados -</h1>
            <section class="d-flex justify-content-center align-items-center">
                <div id="chart" class="content-chart">
                    <apx-chart
                    *ngIf="chartOptions && showChart"
                    [series]="chartOptions.series"
                    [chart]="chartOptions.chart"
                    [labels]="chartOptions.labels"
                    [responsive]="chartOptions.responsive"
                    [title]="chartOptions.title"
                    ></apx-chart>
                </div>
                <div id="chart-bars" class="content-chart">
                    <apx-chart
                    *ngIf="chartOptionsBar && showChart"
                    [series]="chartOptionsBar.series"
                    [chart]="chartOptionsBar.chart"
                    [dataLabels]="chartOptionsBar.dataLabels"
                    [plotOptions]="chartOptionsBar.plotOptions"
                    [yaxis]="chartOptionsBar.yaxis"
                    [xaxis]="chartOptionsBar.xaxis"
                    [fill]="chartOptionsBar.fill"
                    [title]="chartOptionsBar.title"
                    ></apx-chart>
                </div>
            </section>
        </div>
    </div>
</div> 