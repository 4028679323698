import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Observable } from 'rxjs';
import  {map} from 'rxjs/operators';
import { SessionService } from '../interceptors/session.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {

  constructor(
    private msalService: MsalService,
    private router: Router,
    private sessionService: SessionService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
    return this.sessionService.getLoginStatus().pipe(
      map(loggedIn => {
        const accounts = this.msalService.instance.getAllAccounts();
        const currentUrl = state.url;
        const urlTemp = localStorage.getItem('url-detail-nav');
        
        if (urlTemp == null || (currentUrl.includes('/booking/detail') && urlTemp != 'remove')) {
          localStorage.setItem('url-detail-nav', currentUrl)
        } 
        
        if(loggedIn && accounts.length > 0) {
          return true;
        } else {
          this.router.navigate(['emma']);
          return false;
        }
      })
    );
  }
}
